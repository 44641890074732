// **  Initial State
const initialState = {
}

const authReducer = (state = initialState, action) => {


  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        auth: action.data
        // [action.config.storageTokenKeyName]: action[action.config.storageTokenKeyName],
        // [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName]
      }
    case 'LOGOUT':
      const obj = { ...action }
      delete obj.type
      return { ...state, userData: {}, ...obj }
    default:
      return state
  }
}

export default authReducer
