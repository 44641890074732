
// ** Initial State
const initialState = {
  userOnboard: false
}

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_ON_BOARD':
      return { ...state, userOnboard: action.value }
    default:
      return state
  }
}

export default layoutReducer
