// ** Redux, Thunk & Root Reducer Imports
import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
import rootReducer from '../reducers/rootReducer'
import { createStore, applyMiddleware, compose } from 'redux'
import storage from "redux-persist/es/storage"
import {persistReducer, persistStore} from "redux-persist"
// ** init middleware
const middleware = [thunk, createDebounce()]
const persistConfig = {
    key: 'root',
    storage
}
// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const persistedReducer = persistReducer(persistConfig, rootReducer)
// ** Create store
const store = createStore(persistedReducer, {}, composeEnhancers(applyMiddleware(...middleware)))
const persister = persistStore(store)
export { store, persister }
