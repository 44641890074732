// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import user from './user'
import org from './org'
import appinit from './appinit'
import groups from './groups'
const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  user,
  org,
  appinit,
  groups
})

export default rootReducer
