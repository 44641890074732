// **  Initial State
const initialState = {
  org: {}
}

const orgReducer = (state = initialState, action) => {
 const org =  action.data
  switch (action.type) {
    case "STORE_ORG":
      return {
        ...state.org,
          org
        // [action.config.storageTokenKeyName]: action[action.config.storageTokenKeyName],
        // [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName]
      }

    default:
      return state
  }
}

export default orgReducer
